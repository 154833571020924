<template>
  <header
    v-if="currentRouteName != 'wait'"
    class="w-full h-16 flex shadow-xl z-40 bg-white relative"
  >
    <div
      class="flex justify-between w-full max-w-7xl m-auto items-center font-bold text-sm sm:text-base uppercase"
    >
      <div>
        <router-link :to="homelink">
          <span class="hidden sm:inline-block"
            ><img src="@/assets/vectors/logo.svg" /></span
        ></router-link>
      </div>
      <div>
        <ul class="flex gap-4 text-base font-extrabold relative z-50">
          <li>
            <router-link to="/contact">Contact</router-link>
          </li>
          <li>|</li>
          <li>
            <a href="https://www.tecnifibre.com/fr/tennis/" target="_blank">
              <span class="sm:inline-block">Produits éligibles</span></a
            >
          </li>
          <li>|</li>
          <li class="hidden sm:block">
            <router-link to="/">ACCÈS MEMBRES</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      homelink: "/",
    };
  },
  watch: {
    currentRouteName() {
      const id = sessionStorage.getItem("_id");
      const uid = sessionStorage.getItem("uid");
      if (
        (id && uid && uid !== null && id !== null) ||
        (this.$route.params._id && this.$route.params.secu)
      ) {
        this.homelink = `/status/${id || this.$route.params._id}/${
          uid || this.$route.params.secu
        }`;
      }
    },
  },
  mounted() {},
  methods: {
    logout() {
      sessionStorage.clear();
      window.location.href = "/login";
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
