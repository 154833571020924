<template>
  <footer>
    <div>
      <div class="bg-black/30 bgfooter w-full flex py-4 items-center">
        <div class="max-w-7xl flex justify-between items-center m-auto w-full">
          <div>
            <p class="font-bold uppercase italic text--white text-xl mb-2">
              Suivez-nous
            </p>
            <div class="flex justify-start w-48 w-fit gap-4 mb-4">
              <a href="https://www.facebook.com/tecnifibre" target="_blank"
                ><img
                  src="@/assets/vectors/ico-facebook.svg"
                  class="h-10 w-10 m-auto"
              /></a>
              <a href="https://twitter.com/tecnifibre" target="_blank"
                ><img
                  src="@/assets/vectors/icon-twitter.svg"
                  class="h-10 w-10 m-auto"
              /></a>
              <a href="https://www.instagram.com/tecnifibre" target="_blank"
                ><img
                  src="@/assets/vectors/icon-instagram.svg"
                  class="h-10 w-10 m-auto"
              /></a>
              <a
                href="https://www.youtube.com/user/TecnifibreTennis"
                target="_blank"
                ><img
                  src="@/assets/vectors/icon-youtube.svg"
                  class="h-10 w-10 m-auto"
              /></a>
            </div>
          </div>
          <div>
            <a href="https://www.tecnifibre.com/fr/accueil" target="_blank"
              ><img src="@/assets/images/logo2.png" class="w-56"
            /></a>
          </div>
        </div>
      </div>
      <div class="h-12 w-full">
        <div class="w-full max-w-7xl p-4 flex justify-between m-auto">
          <div class="text-center text-xs w-full">
            <ul class="flex justify-center items-center gap-1">
              <li>© 2022-2024, Tecnifibre</li>
              <li>-</li>
              <li>
                <a
                  href="https://oxoformdocs.fra1.digitaloceanspaces.com/66b5ae075541b500867c51ad/politique.pdf"
                  target="_blank"
                  >Politique de confidentialité</a
                >
              </li>
              <li>-</li>
              <li>
                <a
                  href="https://oxoformdocs.fra1.digitaloceanspaces.com/66b5ae075541b500867c51ad/reglement.pdf"
                  target="_blank"
                  >Réglement</a
                >
              </li>
              <li>-</li>
              <li>
                <a
                  href="https://oxoformdocs.fra1.digitaloceanspaces.com/66b5ae075541b500867c51ad/mentions.pdf"
                  target="_blank"
                  >Mentions légales</a
                >
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Example",
  components: {},
};
</script>

<style scoped>
.bgfooter {
  background: url(@/assets/images/bg-footer.png);
  background-size: 50% auto;
  background-color: #1d1d1d;
  padding-bottom: 0;
  color: #fff;
}
</style>
